<div [class]="body.primary.margin_top ? 'mt-5 mt-xl-6' : ''">
    <div [class]="body.primary.margin_bottom ? 'mb-5 mb-xl-6' : ''">
        <div class="background-pink py-5 py-xl-6">
            <div class="container">
                <div class="row">
                    <div
                        class="col-xl-7 d-flex align-items-center justify-content-center"
                        [class.order-xl-1]="body.primary.video_right"
                        appAos
                    >
                        <video controls class="w-100" [poster]="body.primary.thumbnail.url">
                            <source [src]="'/assets/videos/' + body.primary.video" type="video/mp4" />
                        </video>
                    </div>
                    <div
                        class="col-xl-5 mt-5 mt-xl-0 d-flex align-items-center"
                        [class.pe-xl-6]="body.primary.video_right"
                        [class.ps-xl-6]="!body.primary.video_right"
                        [class.order-xl-0]="body.primary.video_right"
                        appAos
                    >
                        <div>
                            <div [innerHTML]="body.primary.title | renderHtml : htmlSerializerTitle"></div>
                            <div [innerHTML]="body.primary.description | renderHtml : htmlSerializer"></div>
                            <div class="row mt-5">
                                <div class="col-auto">
                                    <img
                                        [src]="body.primary.person_image.url"
                                        [alt]="body.primary.person_image.alt"
                                        [title]="body.primary.person_image.alt"
                                        class="avatar"
                                    />
                                </div>
                                <div class="col">
                                    <p class="color-white">
                                        <span class="fw-bold">{{ body.primary.person_name }}</span
                                        ><br />
                                        {{ body.primary.person_position }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
