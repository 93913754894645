<div class="container py-5">
    <div class="row">
        <div class="col-12">
            @if (body.primary?.video_name) {
            <video controls class="w-100" [poster]="body.primary?.image?.url">
                <source [src]="'/assets/videos/' + body.primary?.video_name" type="video/mp4" />
            </video>
            } @else {
            <img [attr.data-src]="body.primary?.image?.url" [alt]="body.primary?.image.alt" class="w-100 lazyload" />
            }
            <ng-template #image>
                <img [attr.data-src]="body.primary?.image?.url" [alt]="body.primary?.image.alt" class="w-100 lazyload" />
            </ng-template>
        </div>
    </div>
</div>
