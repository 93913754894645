import { Component, Input } from '@angular/core';
import { BooleanField, ImageField, KeyTextField, RichTextField, Slice } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-testimonial-slice',
    templateUrl: './testimonial-slice.component.html',
    styleUrls: ['./testimonial-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class TestimonialSliceComponent {
    @Input() body!: TestimonialSlice;

    constructor() {}

    htmlSerializerTitle: HTMLMapSerializer = {
        paragraph: ({ children }) => `<p class="color-white text-size-m">${children}</p>`,
        heading3: ({ children }) => `<h3 class="color-white">${children}</h3>`
    };

    htmlSerializer: HTMLMapSerializer = {
        paragraph: ({ children }) => `<p class="color-white">${children}</p>`
    };
}

export type TestimonialSlice = Slice<
    'testimonial',
    {
        title: RichTextField;
        description: RichTextField;
        person_name: KeyTextField;
        person_position: KeyTextField;
        person_image: ImageField;
        thumbnail: ImageField;
        video: KeyTextField;
        video_right: BooleanField;
        margin_top: BooleanField;
        margin_bottom: BooleanField;
    },
    never
>;
