import { Component, Input, OnInit } from '@angular/core';
import { FilledLinkToMediaField, ImageField, KeyTextField, Slice } from '@prismicio/client';

@Component({
    selector: 'app-full-image-slice',
    templateUrl: './full-image-slice.component.html',
    styleUrls: ['./full-image-slice.component.scss'],
    standalone: true,
    imports: []
})
export class FullImageSliceComponent implements OnInit {
    @Input() body!: FullImageSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type FullImageSlice = Slice<
    'vollbild',
    {
        image: ImageField;
        video: FilledLinkToMediaField;
        video_name: KeyTextField;
    },
    never
>;
